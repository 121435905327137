<template>
    <main id="more-crisps-box" ref="mainBox">
        <div v-show="spinning" class="loading-box">
            <a-spin
            class="loading-child"
            tip="Loading..."
            :spinning="spinning"
            :delay="500"
            >
            </a-spin>
        </div>
        <div class="chips-content">
        <!-- 风暴 -->
          <article class="storm-box">
            <div class="storm-title">
              <div class="home-back">
                    <img class="back-img" src="../../../assets/img/storm/icon/homeBlack.svg" @click="backFun" width="22px">
                    <img
                    class="load-img"
                    src="../../../assets/img/myMindIcon/right-arrow.svg"
                    alt=""
                    />
                </div>
              <h2 class="storm-name">
                {{ chipsTypeName }}
              </h2>
            </div>
            <div class="storm-container">
              <div class="storm-item" v-for="(item,index) in chipsFeedsData" :key="item.id+index" @click="commentCrips(item.id)">
                <div class="storm-head">
                  <div class="user-info">
                    <img 
                      v-if="item.isMember"
                      class="member-crown"
                      src="../../../assets/img/groupImg/crown.svg"
                      />
                    <img class="user-img" 
                      src="../../../../static/img/userData/avatar_yellow_man.png" 
                      v-real-img="httpImageToPrefix(item.userAvatar)"
                      :alt="item.username">
                    <div class="user-name">
                      {{item.username}}
                    </div>
                  </div>
                  <button class="payment-use" v-if="item.isUsable" >
                      <label class="cursor-pointer">{{ item.mbeansRequired }}</label>
                      <img src="../../../assets/img/storm/mbeans.svg" alt="" width="10px" style="margin:0 3px;">
                      <label class="cursor-pointer">{{ getString(strings.Mind_Edit_Use) }}</label>
                  </button> 
                </div>
                <div class="chips-pc-box">
                      <div class="chips-pc-cover" >
                        <img :src="httpImageToPrefix(item.cover)" alt="" />
                        <!-- 是否存在音频内容 -->
                        <div class="chips-pc-audio" v-if="item.isAudio">
                          <img
                            src="../../../assets/img/storm/storm_commend_data_icon.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="chips-pc-text">
                        <div class="chips-pc-title">
                          <span >{{
                            item.title
                          }}</span>
                        </div>
                        <div class="chips-pc-text-content">
                          <span>
                            {{ item.subtitle }}
                          </span>
                        </div>
                        <div class="chips-pc-text-reason" v-if="item.reason">
                          <span> 驳回理由： {{ item.reason }} </span>
                        </div>
                        <div class="chips-pc-bottom">
                          <div class="chips-pc-style">
                            <span
                              v-for="(itemStyle, index) in item.tags"
                              :key="index"
                              @click.stop="stormToBookTag(itemStyle.id)"
                              >{{ itemStyle.name }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
              </div>

            </div>

          </article>
        </div>
        <footer>
          <div class="load-more" @click="loadMoreGroupData" v-if="loadStatus=='load'">
            {{getString(strings.Load_More)}}
          </div>
          <div class="loading" v-else-if="loadStatus=='loading'">
            {{getString(strings.Loading)}}
          </div> 
          <div class="it-over" v-else-if="loadStatus=='over'">
            {{getString(strings.It_Is_Over)}}~
          </div> 
        </footer>
       
  </main>
</template>
<script>
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import {
 postCrispsGetTagPage
} from "../../../common/netWork/crisps_api";
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
function ajaxPromise(callback,params,that){
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}
let tagId = '';
export default {
  inject: ["reload"],
  components:{
  },
  props:[],
  data() {
    return {
      strings, //语言包
      spinning: false,
      nowElectron: false,
      chipsTypeName: getString(strings.Public_Header_Mind),
      chipsFeedsData:[],//风暴数据
      loadMoreDataTime :null,
      loadMoreDataFlag: false, //是否可以请求列表数据
      lastId:'',
      loadStatus:'load'
    };
  },
  created() {
    this.nowElectron = this.$tools.isElectron();
    this.getFirstData();

  },
  methods: {
    getString(i) {
      return getString(i);
    },
    httpImageToPrefix(src) {
      return httpImageToPrefix(src)
    },
    backFun(){
      if (window.history.length > 1) {
        const stormBackRoute = JSON.parse(sessionStorage.getItem('stormBackRoute')) ;
        if (stormBackRoute) {
          if (this.$router.history.current.fullPath==stormBackRoute) {
            this.$router.push('/Home/Storm')
            return
          }
          this.$router.push(stormBackRoute)
          this.reload()
        }
      } else {
        this.$router.push('/')
      }
    },
    //获取数据
    getFirstData(){
      tagId =  this.$route.query.tag || '';
      let chipsTypeName =  this.$route.query.tag;
      if (chipsTypeName) {
        this.chipsTypeName =  chipsTypeName;
      }
      this.spinning = true;
      const obj ={
        tagId,
        type:0,
        lastId:'',
      }
      this.chipsFeedsData=[];
      ajaxPromise(postCrispsGetTagPage,obj,this)
        .then((res) => {
         if (!!res) {
           const crisps = res.crispsFeeds;
            if (!!crisps && crisps.length>0) {
              let lastItem = crisps[crisps.length - 1]
              if (lastItem) {
                this.lastId = lastItem.id
                this.loadMoreDataFlag = true;
                this.loadMoreData();
              }
              this.chipsFeedsData = crisps;
            } 
         }
        })
        .finally(() => {
          this.spinning = false;
          // console.log(this.chipsFeedsData);
        
        });
    },
    loadMoreGroupData(){
      if (this.loadMoreDataFlag) {
        this.loadStatus='loading'
          const obj ={
            tagId,
            type:0,
            lastId:this.lastId,
          }
          ajaxPromise(postCrispsGetTagPage,obj,this)
            .then((res) => {
             if (!!res) {
                const crisps = res.crispsFeeds || [];; 
                if (!!crisps && crisps.length>0) {
                    let lastItem = crisps[crisps.length - 1]
                    if (lastItem) {
                        this.lastId = lastItem.id
                    }
                    this.chipsFeedsData = [...this.chipsFeedsData,...crisps]
                    this.loadMoreDataFlag = true;
                    this.loadStatus='load'
                }else{
                  this.loadMoreDataFlag = false;
                  this.loadStatus='over'
                }
             }
            })
            .finally(() => {
              // console.log(obj,this.chipsFeedsData);
            });
      }
    },
    loadMoreData() {
      this.$nextTick(() => {
        let box = this.$refs.mainBox;
        if (box) {
          const onMouseWheel = (e) => {
              clearTimeout(this.loadMoreDataTime);
              this.loadMoreDataTime = setTimeout(() => {
                let ev = e || window.event;
                let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
                down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
                if (down) {
                  this.loadMoreGroupData();
                }
              }, 100);
          }
          box.removeEventListener("mousewheel",onMouseWheel,true);
          box.addEventListener("mousewheel",onMouseWheel,true);
        }
      })
    },
    commentCrips(id) {
      if (this.nowElectron) {
        let url = "/c/" + id;
        this.openMind(url, { type: 'storm' });
      } else {
        let newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/c/" + id,
        });
        newWindow.location = routerData.href;
      }
    },
    openMind(item,type) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item, type);
    },
     // 设置返回按钮路由
    setStormBackRoute(){
      sessionStorage.setItem('stormBackRoute',JSON.stringify(this.$router.history.current.fullPath));
    },
    //标签跳转订阅
    stormToBookTag(tag) {
      this.setStormBackRoute();
      this.$router.push({
        path: "/Home/moreCrisps",
        name: 'moreCrisps',
        query: {
          tag,
        },
      })
      this.getFirstData();
    }, 
  },
  

 
};
</script>

<style lang="less" scoped>



#more-crisps-box {
  width: 100%;
  height: inherit;
  background: #F5F5F5;
  margin: 0 auto;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
  /* 加载动画开始 */
  .loading-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);
    z-index: 300;
  }
  .loading-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
  }
  .ant-spin-dot-item {
    background-color: #fd492b;
  }
  .ant-spin {
    color: #fd492b;
  }
  .ant-spin .ant-spin-dot-item {
    background-color: #fd492b !important;
  }
  /* 加载动画结束 */
  .chips-content{
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto ;
    background: #FFFFFF;
    article{
      width: 100%;
      padding: 20px;
    }
    .storm-box{
      width: 100%;
      background: #FFFFFF;
      .storm-title{
        width: 100%;
        display: flex;
        align-items: center;
        .home-back{
            display: flex;
            align-items: center;
            .back-img{
              cursor: pointer;
            }
            .load-img {
              margin: 0 5px;
              margin-top: 2px;
              width: 16px;
              height: 16px;
              display: block;
          }
        }
        .storm-name{
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin: 0;
        }
        
      }
      .storm-container{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          .storm-item{
            width: 572px;
            margin-top: 20px;
            padding: 20px;
            border: 1px solid #EEEEEE;
            border-radius: 8px;
            cursor: pointer;
            &:hover{
              box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
            }
            &:hover .chips-pc-box .chips-pc-text .chips-pc-title{
              color: #FD492B;
            }
            
            .storm-head{
              width: 100%;
              display: flex;
              justify-content: space-between;
              .user-info{
                display: flex;
                align-items: center;
                position: relative;
                .member-crown{
                  position: absolute;
                  left: 0;
                  top: -12px;
                }
                .user-img{
                  width: 24px;
                  height: 24px;
                  background: rgba(0, 0, 0, 0);
                  border-radius: 50%;
                  position: relative;
                }
                .user-name{
                  margin-left: 12px;
                  font-size: 12px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #999999;
                }
              }
              .payment-use{
                // width: 60px;
                height: 24px;
                padding: 0 8px;
                font-size: 12px;
                color: #333;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .chips-pc-box {
              display: flex;
              margin-top: 12px;
              position: relative;
              .chips-pc-cover {
                width: 155px;
                height: 126px;
                min-width: 155px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0);
                opacity: 1;
                border-radius: 10px;
                border: 1px solid #d6d6d6;
                
                cursor: pointer;
                .chips-pc-audio {
                  position: absolute;
                  left: 105px;
                  bottom: -2px;
                  img {
                    width: 45px;
                    height: 39px;
                  }
                }
              }
              .chips-pc-cover img {
                transition: 0.3s;
                cursor: pointer;
                height: 124px;
              }
              .chips-pc-cover img:hover {
                transform: scale(1.1);
                transition: 0.3s;
                cursor: pointer;
              }
              .chips-pc-text {
                width: 100%;
                margin-left: 20px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                .chips-pc-title {
                  height: 22px;
                  line-height: 22px;
                  font-size: 16px;
                  /*font-weight: bold;*/
                  color: #333333;
                  opacity: 1;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    cursor: pointer;
                    transition: 0.3s;
                  }
                }
                .chips-pc-text-content {
                  width: 100%;
                  height: 44px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #999;
                  opacity: 1;
                  margin-top: 8px;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    transition: 0.3s;
                    cursor: pointer;
                  }
                  span:hover {
                    color: #252525;
                    transition: 0.3s;
                  }
                }
                .chips-pc-text-reason {
                  font-size: 12px;
                  font-weight: 400;
                  color: #fd492b;
                }
                .chips-pc-bottom {
                  width: 100%;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 20px;
                  color: #999999;
                  opacity: 1;
                  display: flex;
                  position: relative;
                  align-items: center;
                  .chips-pc-style {
                    display: flex;
                    flex-wrap: wrap;
                    span {
                      margin-top: 5px;
                      padding: 2px 12px;
                      margin-right: 20px;
                      background: #EEEEEE;
                      border-radius: 2px;
                      cursor: pointer;
                      &:hover{
                        color: #fff;
                        background: #FD492B;
                      }
                    }
                  }
                }
              }
            }
          }
      }    
    }   
  }   
}
footer{
  display: flex;
  .load-more{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #333333;
    margin: 20px auto;
    cursor: pointer;
  }
  .loading,.it-over{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #666666;
    margin: 20px auto;
  
  }
}   
</style>
